import { abtservice } from 'public/src/services/abt'
import { parseQueryString } from '@shein/common-function'

export default {
  data() {
    return {
      module_name: 'OrderDetail', // OrderDetail or OrderList
      isReturnOff: false,
      isShowReturnUnavailableModal: false,
      unavailableModal: false,
      abTestConfig: {}
    }
  },
  created() {
    this.getOrdersABTest()
  },
  methods: {
    toggleReturnUnavailableModal() {
      this.isShowReturnUnavailableModal = !this.isShowReturnUnavailableModal
    },
    toggleUnavailableModal() {
      this.unavailableModal = !this.unavailableModal
    },
    async getOrdersABTest() {
      const posKey = this.$route.name === 'UserOrdersDetail' ? 'OrderdetailOldFunc' : 'OrderlistOldFunc'
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ posKeys: `ReturnItem,${posKey}` })
      this.abTestConfig = parseQueryString(abtInfo?.[posKey]?.param || '') || {}
      this.isReturnOff = abtInfo?.['ReturnItem']?.param === 'ON'
    }
  }
}
