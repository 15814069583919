import schttp from 'public/src/services/schttp'
const CACHE = {
  bills: {}
}
const { SiteUID, appLanguage } = gbCommonInfo

function queryOrderDetail(billno) {
  return new Promise(resolve => {
    if (CACHE.bills[billno] === undefined) {
      schttp({
        url: '/api/orders/base/get_order_info/get',
        params: {
          billno: billno
        },
        schttp: { needLogin: true }
      }).then(res => {
        if (res.code == 0 && res.info && res.info.order) {
          CACHE.bills[billno] = res.info.order
          resolve(res.info.order)
        }
      })
    } else {
      resolve(CACHE.bills[billno])
    }
  })
}
export const getOrderDoublePoints = async billno => {
  const orderShowInfo = await queryOrderDetail(billno)
  return orderShowInfo
}
/**
 * 现金支付取消订单挽留文案
 */
export const retainPayMethodLang = (language = {}, paymentMethod) => {
  let retainLang
  switch (paymentMethod) {
    case 'adyen-konbini':
      retainLang = language.SHEIN_KEY_PWA_18741
      break
    case 'dlocal-oxxo':
      retainLang = language.SHEIN_KEY_PWA_18745
      break
    case 'ebanx-baloto':
      retainLang = language.SHEIN_KEY_PWA_18749
      break
    case 'ebanx-oxxo':
      retainLang = language.SHEIN_KEY_PWA_18744
      break
    case 'ebanx-boleto':
      retainLang = language.SHEIN_KEY_PWA_18747
      break
    case 'routepay-oxxo':
      retainLang = language.SHEIN_KEY_PWA_18745
      break 
    case 'routepay-konbini':
      retainLang = language.SHEIN_KEY_PWA_18741
      break
    // case 'ebanx-spei':
    //   retainLang = language.SHEIN_KEY_PWA_16824
    //   break;
    default:
      retainLang = language.SHEIN_KEY_PWA_18743
      break
  }
  return retainLang
}
/**
 * waiting for update 提示语
 */
export const waitingForUpdateTips = (language = {}, paymentMethod, isRW) => {
  let tips
  switch (paymentMethod) {
    case 'adyen-konbini':
      tips = language.SHEIN_KEY_PWA_18748
      break
    case 'dlocal-oxxo':
      tips = language.SHEIN_KEY_PWA_18746
      break
    case 'ebanx-oxxo':
      tips = language.SHEIN_KEY_PWA_18746
      break
    case 'ebanx-baloto':
      tips = language.SHEIN_KEY_PWA_18752
      break
    case 'ebanx-boleto':
      tips = language.SHEIN_KEY_PWA_18751
      break
    case 'ebanx-spei':
      tips = language.SHEIN_KEY_PWA_18740
      break
    case 'PayPal-paypal':
      tips = isRW ? language.SHEIN_KEY_PWA_17312 : language.SHEIN_KEY_PWA_17206
      break
    case 'routepay-oxxo':
      tips = language.SHEIN_KEY_PWA_18746
      break
    case 'routepay-konbini':
      tips = language.SHEIN_KEY_PWA_18748
      break
    default:
      tips = language.SHEIN_KEY_PWA_18742
      break
  }
  return tips
}

// 获取b2b2c文案
function queryOrderStatusText() {
  let storageKey = 'ORDER_STATUS_TEXT'
  let textKey = `${SiteUID}_${appLanguage}_key`
  let allSiteCacheData = null
  let curSiteCacheData = {
    textInfo: [],
    currentTime: 0
  }
  try {
    allSiteCacheData = JSON.parse(localStorage.getItem(storageKey))
    curSiteCacheData = {
      ...curSiteCacheData,
      textInfo: allSiteCacheData?.[textKey] || [],
      currentTime: allSiteCacheData?.currentTime || 0
    }
  } catch (err) {
    curSiteCacheData = {
      textInfo: [],
      currentTime: 0
    }
  }
  return new Promise((resolve, reject) => {
    let { currentTime = 0, textInfo = [] } = curSiteCacheData
    let expired = currentTime + 2 * 60 * 60 * 1000 < new Date().getTime()
    if (textInfo?.length && !expired) {
      resolve(textInfo)
    } else {
      schttp({
        url: '/api/orders/base/getOrderStatusText/get'
      })
        .then(res => {
          if (res?.code == '1000') {
            let result = allSiteCacheData || {}
            if (!expired) {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || []
              }
            } else {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || [],
                currentTime: new Date().getTime()
              }
            }
            localStorage.setItem('ORDER_STATUS_TEXT', JSON.stringify(result))
            resolve(result?.[textKey] || [])
          } else {
            resolve([])
          }
        })
        .catch(() => {
          reject([])
        })
    }
  })
}

function queryCodFrontOrderInfo(billno) {
  return new Promise((resolve) => {
    schttp({
      url: '/api/orders/base/queryCodFrontOrderInfo/query',
      params: { billno },
      schttp: { needLogin: true }
    })
      .then(res => {
        resolve(res || {})
      })
      .catch(() => {
        resolve({})
      })
  })
}

function submitCodOrderAuditResultsInfo(params = {}) {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: '/api/orders/base/submitCodOrderAuditResults/update',
      data: params,
      schttp: { needLogin: true }
    })
      .then(res => {
        resolve(res || {})
      })
      .catch(() => {
        resolve({})
      })
  })
}

export const getOrderStatusText = async () => {
  const textInfo = await queryOrderStatusText()
  return textInfo
}

export const transformPercentList = percentList => {
  if (!percentList?.length) return percentList
  let list = JSON.parse(JSON.stringify(percentList))
  list.map(item => {
    item.day = item?.day || item?.detail_time || ''
    item.percent = item.percent || item?.detail_time_percentage || ''
    return item
  })
  return list
}

export const queryCodFrontOrderInfoFun = async billno => {
  const orderInfo = await queryCodFrontOrderInfo(billno)
  return orderInfo
}

export const submitCodOrderAuditResultsFun = async params => {
  const orderInfo = await submitCodOrderAuditResultsInfo(params)
  return orderInfo
}

export const getRefundReasonListEvt = async params => {
  let data = await schttp({
    url: `/api/orders/base/getRefundReasonList/get`,
    params: params
  })
  return data?.info?.reasonLanguages || []
}

// 各状态固定展示的按钮: 显示在最前面
export function getOrderButtonSorts({
  orderStatus
}) {
  const total_btns = ['one_click_pay', 'edit_address', 'urge_shipment', 'cancel_order', 'view_invoices', 'pay_now', 'repurchase', 'track', 'exchange', 'revoke', 'review', 'return_item', 'upload_report', 'my_review', 'urgent_pickup', 'confirm_delivery', 'urge_delivery', 'verify_now']
  let sort_btns = []
  if (!(+orderStatus)) return sort_btns
  if ([0, 12].includes(+orderStatus)) sort_btns = ['pay_now', 'edit_address']
  if ([1].includes(+orderStatus)) sort_btns = ['one_click_pay', 'edit_address', 'urge_shipment', 'cancel_order', 'view_invoices']
  if ([3].includes(+orderStatus)) sort_btns = ['repurchase', 'view_invoices']
  if ([4].includes(+orderStatus)) sort_btns = ['one_click_pay', 'edit_address', 'track', 'urge_shipment', 'exchange', 'view_invoices', 'revoke', 'cancel_order', 'repurchase']
  if ([5].includes(+orderStatus)) sort_btns = ['review', 'return_item', 'upload_report', 'my_review', 'view_invoices', 'track', 'repurchase']
  if ([6].includes(+orderStatus)) sort_btns = ['edit_address', 'urge_shipment', 'view_invoices', 'revoke']
  if ([7].includes(+orderStatus)) sort_btns = ['confirm_delivery', 'review', 'return_item', 'my_review', 'urgent_pickup', 'view_invoices', 'repurchase', 'track']
  if ([8].includes(+orderStatus)) sort_btns = ['repurchase']
  if ([9].includes(+orderStatus)) sort_btns = ['repurchase', 'track']
  if ([10].includes(+orderStatus)) sort_btns = ['track', 'urge_delivery', 'edit_address', 'confirm_delivery', 'review', 'upload_report', 'return_item', 'view_invoices', 'my_review', 'repurchase']
  if ([11].includes(+orderStatus)) sort_btns = ['repurchase', 'track', 'review', 'my_review', 'view_invoices']
  if ([13].includes(+orderStatus)) sort_btns = ['verify_now', 'edit_address', 'repurchase']
  if ([14, 15].includes(+orderStatus)) sort_btns = ['urgent_pickup', 'track', 'review', 'my_review', 'view_invoices']
  if ([16].includes(+orderStatus)) sort_btns = ['edit_address', 'view_invoices']
  if ([17].includes(+orderStatus)) sort_btns = ['track', 'repurchase', 'review', 'my_review', 'view_invoices']
  if ([18].includes(+orderStatus)) sort_btns = ['confirm_delivery', 'track', 'review', 'return_item', 'upload_report', 'view_invoices', 'my_review', 'repurchase']
  if ([19].includes(+orderStatus)) sort_btns = ['track', 'repurchase', 'review', 'my_review', 'view_invoices']

  const clones = JSON.parse(JSON.stringify(sort_btns))
  total_btns.forEach(btn => {
    if (!sort_btns.includes(btn)) clones.push(btn)
  })

  return clones
}

export const queryRefundOrderExpireAmountStatusEvt = async (billList)=>{
  const data = await schttp({
    method: 'POST',
    url: '/api/user/wallet/RefundOrderExpireAmountStatus/get',
    data: { 
      bill_no_list: billList,
    },
  })
  return data || {}
}
