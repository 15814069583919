<template>
  <div
    ref="listRef"
    class="order-list-item"
  >
    <slot :pay-now-label-list="payNowLabelList"></slot>
    <div
      v-if="showReturnTimeTip"
      ref="returnTimeRef"
      class="return-time"
    >
      <span
        v-if="left"
        :style="{ left: `${left}px` }"
        class="return-time__arrow"
      >
      </span>
      <ReturnTimeTip
        :order-info="order"
        :public-local-data="publicLocalData"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ReturnTimeTip from 'public/src/pages/user/child_pages/orders_bff/common/returnTime/ReturnTimeTip.vue'
export default {
  name: 'OrderListItem',
  components: {
    ReturnTimeTip
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      left: 0
    }
  },
  computed: {
    ...mapState('orderList', ['language', 'orders', 'abtInfos', 'publicLocalData']),
    // 利诱类型abt实验列表
    testTypeList() {
      const abtTypeInfo = this.abtInfos?.unpaidOrderTestAbt?.OrderlistInducementType
      if (typeof abtTypeInfo === 'string' && abtTypeInfo) {
        return abtTypeInfo.split(',')
      }
      return []
    },
    // 利诱列表
    payNowLabelList() {
      const labelList = []
      const { unpaid_lure_point_info = {} } = this.order || {}
      // 1-订单优惠信息
      if (unpaid_lure_point_info.type == 'save') {
        labelList.push({
          type: 'discount',
          name: 'sui_icon_coupon_discount_14px',
          inducementType: '1',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      // 2-免邮信息
      if (unpaid_lure_point_info.type == 'freeShipping') {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_shipping_14px',
          inducementType: '2',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      // 3-免费退信息
      if (unpaid_lure_point_info.type == 'freeReturn') {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_return_14px',
          inducementType: '3',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      // 4-价保信息
      if (unpaid_lure_point_info.type == 'priceGuarantee') {
        labelList.push({
          type: 'success',
          name: 'sui_icon_guarantee_price_green_14px',
          inducementType: '4',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      labelList.sort((a, b) => {
        return this.testTypeList.indexOf(a.inducementType) - this.testTypeList.indexOf(b.inducementType) 
      })
      return labelList
    },
    showReturnTimeTip () {
      return this.order?.returnEffect?.desc || ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.adjuctOrderListMore()
      this.getArrowPosition()
    })
  },
  updated () {
    this.$nextTick(() => {
      this.adjuctOrderListMore()
      this.getArrowPosition()
    })
  },
  methods: {
    // 判断按钮列表一行是否放得下
    adjuctOrderListMore() {
      this.orders?.forEach(item => {
        let btnMoreList = item.btnMoreList || []
        const listBtnBillno = $(`#list-btn-${item.billno}`)
        const boxWidth = listBtnBillno?.width()
        const buttonList = listBtnBillno?.find('button.order-list-bot__btn')
        let buttonWidth = 0
        if (boxWidth > 0) {
          let overIndex = 0
          buttonList?.each((i, n) => {
            const obj = $(n)
            const objParent = obj?.parent()
            const objTop = objParent?.hasClass('comment-btn-op') ? objParent : obj
            buttonWidth = buttonWidth + objTop?.width()
            if (buttonWidth + 80 > boxWidth) {
              overIndex = i
            }
          })
          if (overIndex > 0) {
            this.$set(item, 'isShowBtnMore', true)
            buttonList?.each((i, n) => {
              const obj = $(n)
              const objParent = obj?.parent()
              let addClass = ''
              const objTop = objParent?.hasClass('comment-btn-op') ? objParent : obj
              if (obj.hasClass('opacity-item') || obj.hasClass('disabled-address_edit')) {
                addClass = 'opacity-item'
              }
              if (i >= overIndex) {
                btnMoreList.unshift({
                  el: obj,
                  text: obj.html(),
                  addClass: addClass
                })
                objTop?.remove()
              }
            })
          }
        }
        if (btnMoreList?.length) {
          this.$set(item, 'btnMoreList', btnMoreList)
        }
      })
    },
    // 箭头跟随return_item移动
    getArrowPosition() {
      const containerDom = this.$refs.returnTimeRef
      let buttonDom

      const inMoreBtns = this.order?.btnMoreList?.some(item => item?.text.includes(this.language.SHEIN_KEY_PWA_33481))
      if (inMoreBtns) {
        buttonDom = this.$refs.listRef.querySelector('.my-reviewed-comment')
      } else [
        buttonDom = this.$refs.listRef.querySelector('.j-return-btn')
      ]

      if (containerDom && buttonDom) {
        const containerRect = containerDom.getBoundingClientRect()
        const accountRect = buttonDom.getBoundingClientRect()
        const leftPosition = accountRect.left + accountRect.width / 2 - containerRect.left
        this.left = leftPosition
      } else {
        this.left = 0
      }
    }
  }
}
</script>
<style lang="less" scoped>
.return-time {
  position: relative;
  &__arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 6px solid #f6f6f6;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
