import schttp from 'public/src/services/schttp'
import { getQueryString } from '@shein/common-function'
import orderStatusList from '../orderStatus'
import { getOrderAbtInfos } from './getOrderAbtInfos'
import { getBffVersionBySsr } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { getLanguagueData, getHistoryListSer } from '../service'

const { PUBLIC_CDN, langPath, SiteUID, WEB_CLIENT, GB_cssRight, lang, appLanguage, dateLangMap, APPLE_PAY_CODE_LIST, HAPPY_RETURN_CHANNEL, RESALE_SITE_LINK } = gbCommonInfo

export const isBffOrderList = getBffVersionBySsr('order_list_v1')

const getBaseParams = ({ type, page  } = {}) => {
  const statusType = type || getQueryString({ key: 'status_type' }) || ''
  return {
    statusType: orderStatusList.find(_ => _.id == statusType)?.type || 'all',
    page: page || 1,
    limit: 10
  }
}

function getLocals () {
  const locals = {
    langPath,
    PUBLIC_CDN,
    SiteUID,
    WEB_CLIENT,
    GB_cssRight,
    lang,
    appLanguage,
    dateLangMap
  }
  const apolloInfo = {
    APPLE_PAY_CODE_LIST,
    HAPPY_RETURN_CHANNEL,
    RESALE_SITE_LINK
  }
  return {
    locals,
    apolloInfo
  }
}

async function getRelationAccount () {
  const res = await schttp({ // 关联账号
    url: '/user/account/queryAccountInfo',
    params: {
      scene: 'order_list',
      third_party_type: gbCommonInfo.user.account_type
    },
    useBffApi: true
  })
  return res?.info?.exists_relation_account || 0
}

// 订单列表基础数据
export const getBffBaseData = async (type, { page, keyword = '' } = {}) => {
  const [listData, historyOrders, abtInfos, noticeData, relationAccount, currencySupport] = await Promise.all([
    keyword
      ? getBffDataBySearchKeyword({ page, keyword, fetchLangs: 1 })
      : getBffListData({ type, fetchLangs: 1 }), // 列表和多语言
    getHistoryListSer(),
    getOrderAbtInfos(), // abt
    schttp({ // 公告
      url: '/sys/announcement',
      params: { position: '2' },
      useBffApi: true
    }),
    getRelationAccount(),
    schttp({ // 站点支持币种，待支付融合完成删除
      url: '/api/orders/base/currencySupport/query'
    }),
  ])

  return {
    ...listData || {},
    abtInfos,
    historyOrders,
    orderStatusList,
    relationAccount,
    orderListNotice: noticeData?.info?.announcement?.[0]?.content || '',
    // 基础信息
    ...getLocals(),
    currencySupport
  }
}

// 获取列表数据
export const getBffListData = async ({ type, page, fetchLangs = 0 } = {}) => {
  const [bffData, i18n] = await Promise.all([
    schttp({
      url: '/order/list',
      params: getBaseParams({ type, page }),
      useBffApi: true,
      schttp: { needLogin: true }
    }), // 列表
    fetchLangs ? getLanguagueData() : Promise.resolve({}) // 多语言
  ])
  return {
    ...bffData?.info || {},
    code: bffData?.code || '',
    tips: bffData?.msg || '',
    ...(fetchLangs ? { i18n: i18n?.info?.result || {} } : {})
  }
}

// 订单搜索数据
export const getBffDataBySearchKeyword = async ({
  keyword = '',
  limit = 10,
  page = 1,
  fetchLangs = 0
} = {}) => {
  const [bffData, i18n] = await Promise.all([
    schttp({
      url: '/order/search_order_goods',
      params: {
        keyword,
        limit,
        page
      },
      useBffApi: true
    }),
    fetchLangs ? getLanguagueData() : Promise.resolve({}) // 多语言
  ])
  return {
    ...bffData?.info || {},
    code: bffData?.code || '',
    tips: bffData?.msg || '',
    ...(fetchLangs ? { i18n: i18n?.info?.result || {} } : {})
  }
}
