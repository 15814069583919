
if (typeof window !== 'undefined') {
  $(function () {
    window.saObj = {
      enterDetail: function ({ orderId, status_type } = {}) {
        sa('send',
          {
            activity_name: 'click_order_list_view_details',
            activity_param: {
              order_id: orderId,
              status_type
            }
          })
      },
      clickUnshippedCancelOrder: function (order_id) {
        sa('send', {
          activity_name: 'click_unshipped_cancel_order',
          activity_param: {
            order_id
          }
        })
      },
      expose_popup_unshipped_cancel_confirmation () {
        sa('send', {
          activity_name: 'expose_popup_unshipped_cancel_confirmation'
        })
      },
      click_unshipped_cancel_confirmation_yes () {
        sa('send', {
          activity_name: 'click_unshipped_cancel_confirmation_yes'
        })
      },
      bindEvent: function () {
      }
    }
  })
}
