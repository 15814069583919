module.exports = [
  {
    id: 0,
    orderStatus: 'All orders',
    saParam: { order_status: 'all_orders' },
    title: 'SHEIN_KEY_PWA_16194',
    type: 'all'
  },
  {
    id: 1,
    orderStatus: 'Unpaid',
    title: 'SHEIN_KEY_PWA_15309',
    type: 'waiting_payment'
  },
  {
    id: 2,
    orderStatus: 'Processing',
    title: 'SHEIN_KEY_PWA_15215',
    type: 'processing_order'
  },
  {
    id: 3,
    orderStatus: 'Shipped',
    title: 'SHEIN_KEY_PWA_15213',
    type: 'waiting_receive'
  },
  {
    id: 5,
    orderStatus: 'Review',
    title: 'SHEIN_KEY_PWA_15162',
    type: 'need_review'
  },
  {
    id: 4,
    orderStatus: 'Returns',
    title: 'SHEIN_KEY_PWA_15626',
    type: 'after_sale'
  }
]
