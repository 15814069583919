import { RoeCheckSite, RuCheckSite } from 'public/src/pages/common/orderLogic/config'
import orderCheckJumpMask from './orderCheckJumpMask'
const { IS_RW } = gbCommonInfo

export default {
  components: {
    orderCheckJumpMask
  },
  methods: {
    jumpMask (obj = {}) {
      // url是切站后跳转到不同域名下的当前站点的子路径
      const { result, shipping_country_name, url, shipping_country_id, options = {} } = obj
      if (IS_RW && (RoeCheckSite.includes(+shipping_country_id) || RuCheckSite.includes(+shipping_country_id))) {
        //
        // roe国家rw没有专门站点，从而给与提示限制
        this.$toast(this.language.SHEIN_KEY_PWA_20335, 3000)
      } else if (result == 0) {
        this.$refs['orderCheckJumpMask'].orderJumpCountry = shipping_country_name
        this.$refs['orderCheckJumpMask'].show = true
        this.$refs['orderCheckJumpMask'].jumpUrl = url
        this.$refs['orderCheckJumpMask'].options = options
      }
    },
    exposeCombinedRefundTip (type, clickType) {
      let { billno, refundGoodsInfo = {} } = this.relationOptions || {}
      let mapGoodsId = []
      let mapBillno = refundGoodsInfo?.order_group_by_billno?.map(item => item.bill_no) || []
      refundGoodsInfo?.order_group_by_billno?.forEach(item => {
        let orderGoodsList = item.refund_order_goods?.map(goods => goods.order_goods_id)
        mapGoodsId = [...new Set(mapGoodsId.concat(orderGoodsList))]
      })
      let baseParams = {
        order_no: billno,
        relation_billno: mapBillno.join(','),
        relation_goods_id: mapGoodsId.join(','),
      }
      if (clickType) {
        baseParams = { ...baseParams, type: clickType }
      }
      daEventCenter.triggerNotice({
        daId: type,
        extraData: baseParams
      })
    }
  }
}
