<template>
  <div
    v-if="canOrderAddressEdit"
    v-expose="{ id: '1-20-1-109', data: analysisParamsInfo }"
    class="address-edit_box"
    :class="{'mshe-fr': isIconScene }"
  >
    <s-button
      v-if="isOrderListOrDetailEdit"
      v-tap="{ id: '1-20-1-110', data: analysisParamsInfo }"
      :type="buttonStyle"
      :class="{'disabled-address_edit': disabledOrderAddressEdit }"
      :disabled="disabled"
      class="order-address_edit order-list-bot__btn order-detail-footer__btn"
      @click="handleEditAddressEvt"
    >
      {{ showShort ? language.SHEIN_KEY_PWA_23929: language.SHEIN_KEY_PWA_16969 }}
    </s-button>
    <!-- 置灰点击提示 -->
    <LazyMount>
      <CommonTipsDialog
        :visible.sync="disabledOrderAddressEditDialog"
        append-to-body
        :content="disabledOrderAddressTip"
        :btn-type="['primary', 'H56PX']"
        :btn-text="language.SHEIN_KEY_PWA_15146"
        @ok="disabledOrderAddressEditDialog = false"
      />
    </LazyMount>
    <!-- 二次确认弹框 -->
    <LazyMount>
      <CommonTipsDialog
        :visible.sync="doubleConfirmTipDialog"
        append-to-body
        :content="doubleConfirmTipText"
        :btn-type="['primary', 'H56PX']"
        :btn-text="language.SHEIN_KEY_PWA_15146"
        @ok="canOrderAddressEditEvt"
      />
    </LazyMount>
    <!-- 批量修改地址确认弹窗 -->
    <LazyMount>
      <EditAddressConfirmDialog
        :visible.sync="showEditAddressConfirmDialog" 
        :language="language"
        :batch-modify-address="batchModifyAddressData"
        :billno="order.billno"
        @confirm="onConfirmModifyAddress"
      />
    </LazyMount>
  </div>
</template>
<script>
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
export default {
  directives: { tap, expose }
}
</script>
<script setup>
import { ref, computed } from 'vue'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import orderLogic from 'public/src/pages/common/orderLogic/index'
import CommonTipsDialog from 'public/src/pages/user/child_pages/orders/common/commonTipsDialog'
import EditAddressConfirmDialog from 'public/src/pages/user/child_pages/orders/common/edit-address/EditAddressConfirmDialog'
import { getDisabledConfig, getExtraConfig, initBffApiShipAddressConfig } from 'public/src/pages/trackNew/utils'
import { Toast as $toast, LazyMount } from '@shein/sui-mobile'
import { multiEditCheckSer } from 'public/src/pages/user/child_pages/orders_bff/list/service.js'

let { UPDATE_SHIPPED_ADDRESS } = typeof window != 'undefined' ? gbCommonInfo : {}
let props = defineProps({
  editAddressNewConfigOptions: {
    type: Object,
    default() {
      return {}
    }
  },
  order: {
    type: Object,
    default() {
      return {}
    }
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  newEditAddressOptions: {
    type: Object,
    default() {
      return {}
    }
  },
  buttonStyle: {
    type: Array,
    default() {
      return ['H56PX']
    }
  },
  showShort: {
    type: Boolean,
    default: false
  },
  isOnline: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

let emit = defineEmits([
  'showEditAddressDialog'
])
let order = computed(() => {
  return props.order || {}
})
let language = computed(() => {
  return props.language || {}
})
let newEditAddressOptions = computed(() => {
  return props.newEditAddressOptions || {}
})
let editAddressNewConfig = computed(() => {
  return props.editAddressNewConfigOptions || {}
})

let isIconScene = computed(() => {
  return newEditAddressOptions.value?.btnSource == 'Icon'
})
// 订单列表页或者订单详情页特殊场景处理
let isOrderListOrDetailEdit = computed(() => {
  let pageSource = newEditAddressOptions.value?.pageSource || ''
  return pageSource == 'OrderList' || pageSource == 'OrderDetail'
})

let canOrderAddressEdit = computed(() => {
  return [1, 2].includes(+editAddressNewConfig.value?.shippingaddr_info?.isOrderShippingAddressEditable)
})
let disabledOrderAddressTip = computed(() => {
  return editAddressNewConfig.value?.editShippingAddressStyle?.tip || ''
})
let disabledOrderAddressEdit = computed(() => {
  return editAddressNewConfig.value?.editShippingAddressStyle?.style == 'gray'
})


let doubleConfirmTipDialog = ref(false)
let doubleConfirmTipText = ref('')

let disabledOrderAddressEditDialog = ref(false)

let showEditAddressConfirmDialog = ref(false)
let batchModifyAddressData = ref({})

let disabledAddress = ref(['country'])
let extraAddress = ref(['isDefault', 'type', 'certificateTw'])

let canOrderAddressEditDialog = ref(false)

let analysisParamsInfo = computed(() => {
  let pageSource = newEditAddressOptions.value?.pageSource || ''
  let baseInfo = {
    order_no: editAddressNewConfig.value?.billno || '',
    editable_type: editAddressNewConfig.value?.shipped_status_info?.editable_type || '',
    is_order_address_editable: editAddressNewConfig.value?.shipped_status_info?.is_order_address_editable || ''
  }
  return pageSource == 'OrderDetail' ? {
    ...baseInfo,
    button_position: isIconScene.value ? 'address_module' : 'footer'
  } : baseInfo
})

let reqBillno = computed(() => {
  return editAddressNewConfig.value?.billno || ''
})

let handleEditAddressEvt = async () => {
  if (!props.isOnline || !window.navigator.onLine) {
    $toast(gbCommonInfo?.language?.SHEIN_KEY_PWA_31762 || language.value?.SHEIN_KEY_PWA_31762)
    return
  }
  // 禁用点击提示文案
  if (disabledOrderAddressEdit.value) {
    disabledOrderAddressEditDialog.value = !!disabledOrderAddressTip.value
    return
  }

  let { shippingaddr_info = {}, shipped_status_info = {} } = editAddressNewConfig.value || {}

  if (shipped_status_info?.double_confirm_tip) {
    doubleConfirmTipDialog.value = true
    doubleConfirmTipText.value = shipped_status_info?.double_confirm_tip
    return
  }

  if (shippingaddr_info?.enable_check_multi_edit == 1) {
    let resInfo = await multiEditCheckSer({
      billNo: reqBillno.value
    })

    let {
      modify_type = '0'
    } = resInfo?.info || {}
    if (!modify_type || modify_type == '0') {
      toEditAddressEvt()
      return
    }
    batchModifyAddressData.value = resInfo?.info || {}
    showEditAddressConfirmDialog.value = true
    return
  }

  toEditAddressEvt()
}

let onConfirmModifyAddress = (crossType) => {
  toEditAddressEvt({
    batchModifyAddressParams: {
      cross_type: crossType,
      modify_type: batchModifyAddressData.value?.modify_type
    }
  })
}

let canOrderAddressEditEvt = () => {
  canOrderAddressEditDialog.value = false
  toDisabledEditAddressEvt()
}

// 正常打开地址编辑页
let toEditAddressEvt = ({ batchModifyAddressParams = {} } = {}) => {
  console.log('toEditAddressEvt=======')
  let { shippingaddr_info = {} } = editAddressNewConfig.value || {}
  let shipInfo = initBffApiShipAddressConfig({ order: shippingaddr_info || {} })
  if (shippingaddr_info?.is_shop_transit == 1) {
    let extraData = orderLogic.getStoreAddressExtraData({ ...(order.value || {}), addTime: order.value?.add_time }) || {}
    emit('showEditAddressDialog', {
      isShop: true,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        type: 'edit',
        addrData: {
          ...shipInfo,
          editAddressFlag: true
        },
        url: '/api/checkout/editShipping/update?billno=' + reqBillno.value,
        ajaxType: 'post',
        countryId: shipInfo.countryId,
        extra: ['certificateTw'],
        disabled: disabledAddress.value,
        extraParams: batchModifyAddressParams || {},
        extraData: {
          ...extraData,
          billno: reqBillno.value
        }
      }
    })
  } else {
    emit('showEditAddressDialog', {
      isShop: false,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        disabled: disabledAddress.value,
        extra: extraAddress.value,
        extraParams: batchModifyAddressParams || {}
      }
    })
  }
}

let toDisabledEditAddressEvt = () => {
  // 获取最新的地址信息
  let { shippingaddr_info = {} } = editAddressNewConfig.value || {}
  let shipInfo = initBffApiShipAddressConfig({ order: shippingaddr_info || {} })

  // 获取置灰地址字段, 携带置灰字段调出编辑地址弹框
  let { payment_method: paymentMethod, orderStatus } = order.value || {}
  // 编辑地址前置状态设置
  let disabledAddressArr = getDisabledConfig({
    orderInfo: order.value,
    TRANSPORT_TYPE_SHOP,
    updateShippedAddress: UPDATE_SHIPPED_ADDRESS?.[0] || {},
    disabledAddress: disabledAddress.value
  })
  disabledAddress.value = disabledAddressArr?.length ? disabledAddressArr : disabledAddress.value
  if (paymentMethod === 'cod' && [6, 4].includes(+orderStatus)) {
    disabledAddress.value = [...new Set([...disabledAddress.value, 'tel', 'standbyTel'])]
  }
  extraAddress.value = getExtraConfig({ shipInfo })
  
  // 是否需要地址联想
  let needAssociatedAddress = disabledAddress.value?.length && disabledAddress.value?.some(item => !(['country', 'tel', 'standbyTel'].includes(item)))
  extraAddress.value = needAssociatedAddress ? [...extraAddress.value, 'associated_address'] : extraAddress.value

  if (shippingaddr_info?.is_shop_transit == 1) {
    let extraData = orderLogic.getStoreAddressExtraData({ ...(order.value || {}), addTime: order.value?.add_time }) || {}
    emit('showEditAddressDialog', {
      isShop: true,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        type: 'edit',
        addrData: {
          ...shipInfo,
          editAddressFlag: true
        },
        url: '/api/checkout/editShipping/update?billno=' + reqBillno.value,
        ajaxType: 'post',
        countryId: shipInfo.countryId,
        extra: ['certificateTw'],
        disabled: disabledAddress.value,
        extraParams: {},
        extraData: {
          ...extraData,
          billno: reqBillno.value
        }
      }
    })
  } else {
    emit('showEditAddressDialog', {
      isShop: false,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        disabled: disabledAddress.value,
        extra: extraAddress.value,
        extraParams: {}
      }
    })
  }
}
</script>
<style lang="less">
.address-edit_box {
  display: inline-block;
  &.mshe-fr{
    float: right !important; /* stylelint-disable-line declaration-no-important */
  }
  .order-address_edit{
    .margin-l(.32rem);
  }
}
.disabled-address_icon {
  i {
    color: #ccc !important; /* stylelint-disable-line declaration-no-important */
  }
}
.disabled-address_edit {
  color: #bbb !important; /* stylelint-disable-line declaration-no-important */
  background: #999 !important; /* stylelint-disable-line declaration-no-important */
  border: none;
}
</style>
