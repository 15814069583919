<template>
  <div class="return-time">
    <div
      class="return-time__desc"
      v-html="timeDesc"
    >
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { template } from '@shein/common-function'
import orderLogic from 'public/src/pages/common/orderLogic'

const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => {}
  },
  publicLocalData: {
    type: Object,
    default: () => {}
  }
})

const timeDesc = computed(() => {
  let returnEffect = props.orderInfo?.returnEffect || {}
  let descFillTime = `${orderLogic.orderDateFormat(returnEffect?.descFillTime, true, props.publicLocalData) || ''}`

  return template(descFillTime, returnEffect?.desc || '')
})
</script>
<style lang='less' scoped>
.return-time {
  display: inline-block;
  width: 100%;
  padding: 0 24/75rem 16/75rem;
  &__desc {
    background: @sui_color_gray_weak2;
    padding: 16/75rem;
    line-height: 1.2;
  }
}
</style>
